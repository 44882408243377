<template>
  <div class="ecposition-header">
    <div class="ecposition-header-letf">
      <!-- <Weather></Weather> -->
      <el-select
        v-model="harbourid"
        @change="changeHarBourid"
        class="harbour-input"
        clearable
        placeholder="请选择"
        popper-class="selectFrom"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="ecposition-header-center">
		<!-- 铁塔数字能源光储充换车算云 -->
	</div>
    <div class="ecposition-header-right">
      <div class="choose">
        <div class="choose-cell" :class="{ choosedindex: choosed == 1 }">
          <span class="text">日统计</span>

          <el-date-picker
            @change="changetime('1')"
            class="pickyear"
            value-format="yyyy-MM-dd"
            v-model="value1"
            type="date"
            :clearable="false"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="choose-cell" :class="{ choosedindex: choosed == 2 }">
          <span class="text">月统计</span>
          <el-date-picker
            @change="changetime('2')"
            class="pickyear"
            v-model="value2"
            value-format="yyyy-MM"
            type="month"
            :clearable="false"
            placeholder="选择月"
          >
          </el-date-picker>
        </div>
        <div class="choose-cell" :class="{ choosedindex: choosed == 3 }">
          <span
            class="DS-Digital-Bold"
            v-if="choosetime && choosetime.length == 4"
            >{{ choosetime }}</span
          >
          <span v-else class="text">年统计</span>
          <el-date-picker
            @change="changetime('3')"
            class="pickyear"
            value-format="yyyy"
            v-model="value3"
            type="year"
            :clearable="false"
            placeholder="选择年"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div id="elmain">
      <!-- 页面主体路由 -->
      <transition name="main" mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/back.scss";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {},
  data() {
    return {
      showFullScreen: true,
      choosed: 1,
      nowDate: "", // 当前日期
      nowTime: "", // 当前时间
      nowWeek: "", // 当前星期
      value1: "",
      value2: "",
      value3: "",
      options: [],
      harbourid: "76df7579ccc24d2693f6488c38a3a0b0",
      choosetime: "",
      timetpye: "",
      timer: "",
      router: "",
    };
  },
  created() {
    localStorage.setItem("harbour_id", "76df7579ccc24d2693f6488c38a3a0b0");
    console.log(this.$route, "监听路由");
    this.router = this.$route.name;
  },
  methods: {
    // 获取电港列表
    getharbour() {
      dataInterface
        .fetchDataInterface("50cb31a4830848049821b3c755c9c8c4")
        .then((res) => {
          console.log(res);
          this.options = res;
          if (localStorage.getItem("harbour_id")) {
            this.harbourid = localStorage.getItem("harbour_id")
              ? localStorage.getItem("harbour_id")
              : "76df7579ccc24d2693f6488c38a3a0b0";
          } else {
            localStorage.setItem("harbour_id", res[0].id);
          }
          setTimeout(() => {
            this.getFacility();
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 更改电港
    changeHarBourid() {
      console.log(55566);
      localStorage.setItem("harbour_id", this.harbourid);
      this.getFacility();
      this.$bus.$emit("onfangdiangonglv");
    },
    //全屏
    clickFullScreen() {
      const docElm = document.documentElement;
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }
      this.showFullScreen = !this.showFullScreen;
    },
    //点击退出全屏
    clickExit() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
      this.showFullScreen = !this.showFullScreen;
    },
    // 获取电港信息
    getFacility() {
      let portId = localStorage.getItem("harbour_id")
        ? localStorage.getItem("harbour_id")
        : "76df7579ccc24d2693f6488c38a3a0b0";
      dataInterface
        .getFacility(portId)
        .then((res) => {
          console.log(res);
          localStorage.setItem("stationSite_id", res.equipmentExchangePassId);
          localStorage.setItem(
            "stationSite_id_shangyong",
            res.equipmentExchangeBussId
          );
          localStorage.setItem(
            "equipmentChargePileIds",
            res.equipmentChargePileIds
          );
          this.$bus.$emit("offCloseFunction");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changetime(type) {
      if (type == 1) {
        localStorage.setItem("changeDataNuber", this.value1);
        this.choosetime = this.value1;
        this.value2 = "";
        this.value3 = "";
        localStorage.setItem("typeTime", "日");
      } else if (type == 2) {
        localStorage.setItem("changeDataNuber", this.value2);
        this.choosetime = this.value2;
        this.value1 = "";
        this.value3 = "";
        localStorage.setItem("typeTime", "月");
      } else if (type == 3) {
        localStorage.setItem("changeDataNuber", this.value3);
        this.choosetime = this.value3;
        this.value1 = "";
        this.value2 = "";
        localStorage.setItem("typeTime", "年");
      }
      this.choosed = type;
      this.$bus.$emit("onfangdiangonglv");
    },
    currentTime() {
      setInterval(this.getDate, 60);
    },
    getDate() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let week = new Date().getDay();
      let hh = new Date().getHours();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      if (week == 1) {
        this.nowWeek = "星期一";
      } else if (week == 2) {
        this.nowWeek = "星期二";
      } else if (week == 3) {
        this.nowWeek = "星期三";
      } else if (week == 4) {
        this.nowWeek = "星期四";
      } else if (week == 5) {
        this.nowWeek = "星期五";
      } else if (week == 6) {
        this.nowWeek = "星期六";
      } else {
        this.nowWeek = "星期日";
      }
      _this.nowTime = hh + ":" + mf + ":" + ss;
      _this.nowDate = yy + "-" + mm + "-" + dd;
    },
  },
  mounted() {
    document.title = "铁塔数字能源光储充换车算云";
    this.timetpye = localStorage.getItem("typeTime");
    this.currentTime();
    this.getharbour();
    this.choosetime = localStorage.getItem("changeDataNuber")
      ? localStorage.getItem("changeDataNuber")
      : "-";
    this.harbourid = localStorage.getItem("harbour_id")
      ? localStorage.getItem("harbour_id")
      : "76df7579ccc24d2693f6488c38a3a0b0";

    this.timer = setInterval(() => {
      this.$bus.$emit("onfangdiangonglv");
    }, 900000);
  },
  // 销毁定时器
  beforeDestroy() {
    this.$bus.$off("onfangdiangonglv");
    clearInterval(this.timer);
    if (this.getDate) {
      clearInterval(this.getDate); // 在Vue实例销毁前，清除时间定时器
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/comm.scss";
.selectFrom {
  background: #084a49;
  color: #fff;
  border: none;

  .el-select-dropdown__item:hover {
    background: #084a49;
    color: #fff;
  }
}

.ecposition-header {
  width: 3840px;
  height: 188px;
  // background-image: url("~@/assets/images/exposition/guangchuchonghuantou.png");
  background-image: url("~@/assets/images/myComm/header-bg.png");
  display: flex;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .ecposition-header-letf {
    flex: 1;
    padding-left: 20px;
    line-height: 68px;
    position: relative;

    .harbour-input {
      position: absolute;
      right: 220px;
      top: 0px;
      height: 20px;
      z-index: 6;

      .el-input__inner {
        background: rgba(74, 160, 176, 0.15);
        border: none;
        color: #ffffff;
      }
    }
  }

  .ecposition-header-center {
    width: 1300px;
    text-align: center;
    color: #ffffff;
	// font-size: 100px;
	// font-weight: 600;
    background: url("~@/assets/images/myComm/header-2.png") no-repeat;
    background-position: 10px 10px;
	background-size: 94%;
  }

  .ecposition-header-right {
    flex: 1;
    position: relative;

    .fullScreenposition {
      position: absolute;
      right: 30px;
      top: 10px;
    }

    .choose-time {
      position: absolute;
      left: 220px;
      top: 18px;
    }

    .letf-box {
      position: absolute;
      right: 63px;
      top: 10px;
      font-family: "DS-Digital-Bold";
    }

    .choose {
      width: 480px;
      height: 40px;
      display: flex;
      justify-content: space-evenly;
      position: absolute;
      right: 50px;
      bottom: 47px;
      font-weight: 600;

      .choose-cell {
        cursor: pointer;
        width: 140px;
        line-height: 40px;
        text-align: center;
        background-image: url("~@/assets/images/exposition/unchoose.png");
        position: relative;
		color: #AFAFAF;
        .pickyear {
          position: absolute;
          left: 0;
          top: 0;
          width: 140px;
          opacity: 0;
        }

        .DS-Digital-Bold {
          font-family: "DS-Digital-Bold";
        }
      }

      .choosedindex {
        background-image: url("~@/assets/images/exposition/choosed.png");
        color: #2983cc;
      }
      .text {
        @include ffamily-HT;
        font-size: 32px;
		margin-left: 38px;
		line-height: 30px;
      }
    }
  }
}
.el-input{
//   height: 25px;
//   line-height: 25px;
  font-size: 20px;
}
</style>
<!-- <style lang="scss" scoped> -->

// .dropdown {
// .el-scrollbar__view .el-select-dropdown__list{
// background:#084A49 ;
// }
// .el-select-drop{
// background: #084A49;
// }
// .el-select-dropdown__item {
// color: #fff;
// background: #084A49;
// }

// .el-select-dropdown__item:hover {
// background-color: #173046 !important;
// }
// }


<!-- </style> -->